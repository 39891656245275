'use strict';

var util = require('./util');

/*
 * Slick was extended!  See jquery-ext.js.  New Slick settings options include:
 *   config {Object} Load a predefined settings object, to be combined with your other custom settings
 *   class {String} Class name(s) to add to the carousel wrapper div. good for themeing.
 *   arrowsInline {Boolean} append the prev/next arrows to the dots container.
 */ 

// Default Slick Carousel settings added to each config below.  These options should apply to every carousel in the site.
var defaultOptions = {
    arrows: true,
    arrowsInline: true,
    dots: true,
    infinite: true,
    mobileFirst: true,
    prevArrow: '<button type="button" class="slick-prev">'+window.Iconography.chev_left+'</button>',
    nextArrow: '<button type="button" class="slick-next">'+window.Iconography.chev_right+'</button>',
    slidesToScroll: 1,
    speed: 300
};


var configurations = {

    // Default settings
    // ex: $('.mycaro').slick({
    //     config: carousel.defaultOptions,
    // });
    defaultOptions: defaultOptions,

    // 4-up slider in desktop
    // ex: $('.mycaro').slick({
    //     config: carousel.standard,
    // });
    standard: $.extend({}, defaultOptions, {
        slidesToShow: 1,
        responsive: [
            {
                breakpoint: util.getViewports('xs') - 1,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows: false,
                    centerMode: true,
                    centerPadding: '50px'
                }
            },
            {
                breakpoint: util.getViewports('sm') - 1,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
                }
            },
            {
                breakpoint: util.getViewports('md') - 1,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3
                }
            },
            {
                breakpoint: util.getViewports('lg') - 1,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 4
                }
            },
            {
                breakpoint: util.getViewports('xl') - 1,
                settings: {
                    slidesToShow: 6,
                    slidesToScroll: 6
                }
            }
        ]
    }),

    medium: $.extend({}, defaultOptions, {
        slidesToShow: 1,
        infinite: false,
        responsive: [
            {
                breakpoint: util.getViewports('xs') - 1,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows: false,
                    centerMode: true,
                    centerPadding: '50px'
                }
            },
            {
                breakpoint: util.getViewports('sm') - 1,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
                }
            },
            {
                breakpoint: util.getViewports('md') - 1,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3
                }
            },
            {
                breakpoint: util.getViewports('lg') - 1,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 4
                }
            }
        ]
    }),

    landing: $.extend({}, defaultOptions, {
        slidesToShow: 1,
        infinite: false,
        responsive: [
            {
                breakpoint: util.getViewports('xs') - 1,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows: false,
                    centerMode: true,
                    centerPadding: '50px'
                }
            },
            {
                breakpoint: util.getViewports('sm') - 1,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
                }
            },
            {
                breakpoint: util.getViewports('md') - 1,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 4
                }
            },
            {
                breakpoint: util.getViewports('lg') - 1,
                settings: {
                    slidesToShow: 5,
                    slidesToScroll: 5
                }
            }
        ]
    }),

    // PDP main product image slider
    // ex: $('.mycaro').slick({
    //     config: carousel.pdp,
    // });
    pdp: $.extend({}, defaultOptions, {
        arrows: false,
        arrowsInline: false,
        dots: true,
        infinite: false,
        slidesToScroll: 1,
        slidesToShow: 1
    }),

    // Hero banner slider, nav overlaid. Divs with class="banner-carousel" automatically get slicked with this.
    // ex: $('.mycaro').slick({
    //     config: carousel.hero,
    // });
    hero: $.extend({}, defaultOptions, {
        adaptiveHeight: true,
        arrows: false,
        prevArrow: '<button type="button" class="slick-prev">'+window.Iconography.hero_caro_arrow_left+'</button>',
        nextArrow: '<button type="button" class="slick-next">'+window.Iconography.hero_caro_arrow_right+'</button>',
        slidesToScroll: 1,
        class: 'slick-hero-ui slick-overlay-nav',
        responsive: [
            {
                breakpoint: util.getViewports('md') - 1,
                settings: {
                    arrows: true
                }
            }
        ]
    }),

    // Small 1-slide carousel with tiny nav UI
    // ex: $('.mycaro').slick({
    //     config: carousel.small,
    // });
    small: $.extend({}, defaultOptions, {
        arrows: false,
        slidesToShow: 1,
        class: 'slick-small-ui'
    })
};

module.exports = configurations;
