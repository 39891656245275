'use strict';

const slickConfigs = require('../config/slickConfigs'),
    $ = window.$,
    debounce = require('../frontend/utils').debounce,
    isMobile = require('../frontend/utils').isMobile,
    applyHeightNormalizer = require('../frontend/utils').getHeightNormalized,
    carouselShelf = require('./carouselShelf');

/**
* Init globally reusable carousels
*/

module.exports = {
    heroCarousels: function () {
        $('.hero-caro').slick(slickConfigs.hero);
    },
    bannerCarousels: function() {
        $('.home-carousel').slick(slickConfigs.banner);
    },
    applyCarousel: function(isFirstExecution) {
        if (isFirstExecution === null || isFirstExecution === false || isFirstExecution === undefined || isFirstExecution === '') return;

        var _self = this;

        this.home = {
            carouselEinstein: '.carouselEinstein',
            firstSlot: 'slot-home-products-1',
            pageWrapper: '.page',
            productTiles: '.product-tile-caro',
            productTilesClass: 'product-tile-caro',
            gridTiles: '.grid-tile',
            bestSellerId: 'bestSeller',
            bestSellerCarousel: '.' + this.bestSellerId + ' ' + this.productTiles,
            slickInitializedClass: 'slick-initialized',
            pdpMain: 'pdpMain',
            elementsToApplySlick: '',
            page: '.page'
        };

        this.pdp = {
            carouselEinstein: '.pdp-main .carouselEinstein',
            productTiles: '.product-tile-caro',
            productTilesClass: 'product-tile-caro',
            slickInitializedClass: 'slick-initialized',
            elementsToApplySlick: ''
        };

        this.observer = '';

        this.getCarouselSettings = function() {
            return slickConfigs.homeProductShelves;
        }.bind(this);

        this.unsetSlick = function(elem, unsetSlickCallbackFunc) {
            if (elem === null || elem === undefined || elem === '') return;
            if($(elem).length) {
                $(elem).each(function(idx, currElem) {
                    if ($(currElem).hasClass(_self.home.slickInitializedClass)) {
                        $(currElem).slick('unslick');
                    }
                });
            }

            _self.home.elementsToApplySlick = $(elem);
            unsetSlickCallbackFunc(_self.home.elementsToApplySlick);

            return this;
        }.bind(this);

        this.applySlickCarousel = debounce(function(elem) {
            if (elem === null || elem === undefined || elem === '') return;

            if($(_self.home.elementsToApplySlick).length) {
                $(_self.home.elementsToApplySlick).each(function(idx, currElem) {
                    if (!$(currElem).hasClass(_self.home.slickInitializedClass)) {
                        $(currElem).slick(_self.getCarouselSettings());

                        this.removeLoading(currElem);
                    }
                }.bind(this));

                _self.home.elementsToApplySlick = '';
            }

            if($(elem).length && !$(elem).hasClass(_self.home.slickInitializedClass)) {
                $(elem).slick(_self.getCarouselSettings());

                this.removeLoading(elem);
            }

            return this;
        }.bind(this), 1000);

        this.removeLoading = function(elem) {
            if (elem === null || elem === undefined || elem === '') return;

            if($(elem).length > 1) {
                $(elem).each(function (idx, currElem) {
                    $(currElem).spinner().stop();
                });
            } else {
                $(elem).spinner().stop();
            }
        }.bind(this);

        this.setWindowFocused = function(ev) {
            ev.preventDefault();

            window.isFocused = true;
        }.bind(this);

        this.applyEinsteinCarouselSlick = function () {
            _self.applySlickCarousel(_self.home.carouselEinstein, false);
        };

        this.reApplySlick = function(ev, cameFrom) {
            if (_self.home.productTiles === undefined || _self.home.productTiles === null || _self.home.productTiles === '') return;

            cameFrom = cameFrom == null || cameFrom == undefined ? false : cameFrom;

            if(!cameFrom.deliveryFile && !window.isFocused) _self.unsetSlick(_self.home.productTiles, _self.applySlickCarousel);

            window.isFocused = false;
        };

        this.handleCardHeight = (res) => {
            console.log('handleCardHeight res', res);

            $(res).each((idx, currShelf) => {
                const currClassList = $(currShelf).prop('classList'),
                    slotIdClass = currClassList[2] && currClassList[2].length ? currClassList[2] : '',
                    slotIdCtx = `.${slotIdClass}`,
                    $currShelf = $(slotIdCtx) && $(slotIdCtx).length && $(slotIdCtx).eq(0),
                    $slotIdChildren = $currShelf && $currShelf.length && $currShelf.find(_self.home.gridTiles),
                    slotIdChildrenClassList = $slotIdChildren && $slotIdChildren.length && $slotIdChildren.prop('classList'),
                    slotIdChildrenCtx = `.${slotIdChildrenClassList[0]}`,
                    childrenCtx = `${slotIdCtx} ${slotIdChildrenCtx}`;

                console.log('initiateProductCardTreatment');
                console.log('applyHeightNormalizer');

                applyHeightNormalizer(slotIdCtx, childrenCtx);
            });

            return res;
        };

        this.handlePriorHeightNormalizer = (res) => {
            _self.applySlickCarousel(res, false);

            return res;
        };

        this.handlePostCardHeight = (res) => {
            carouselShelf.hideCarouselShelf();

            return res;
        };

        this.initiateProductCardTreatment = function(elem) {
            if (!elem) return;

            return new Promise((resolve, reject) => {
                if ($(elem).length) {
                    return resolve(elem);
                }

                return reject('elem ' + elem + ' does not exist. Bailing out!');
            });
        };

        this.treatProductCard = function (elem) {
            if (!elem) return;

            _self.initiateProductCardTreatment(elem)
                .then(_self.handlePriorHeightNormalizer)
                .then(_self.handleCardHeight)
                .then(_self.handlePostCardHeight);
        };

        this.treatEinsteinProductCard = debounce(
            function (ev, carouselObj) {
                if (!carouselObj) return;

                _self.treatProductCard(carouselObj.targetSlick);
            }, 1500
        );

        this.onPageLoad = function () {
            _self.treatProductCard(_self.home.productTiles);
        };

        this.bindPostcodeEvent = function() {
            $('form').on('focus', '#dropdown-postcode-input', function() {
                window.isFocused = true;
            });
        }.bind(this);

        this.bindEvHandlers = function() {
            $(_self.onPageLoad);
            $(window).on('scroll', this.setWindowFocused);
            $(window).on('resize', _self.reApplySlick);
            $(document).on('carousel:loaded', carouselShelf.hideCarouselShelf);
            $(document).on('carousel:loaded', _self.treatEinsteinProductCard);

            if(isMobile()) $(document).ajaxComplete(this.bindPostcodeEvent);

            return this;
        }.bind(this);

        this.bindEvHandlers();
    },
    init: function() {
        this.applyCarousel(true);
    }
};
