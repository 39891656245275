'use strict';


const slickConfigs = require('../config/slickConfigs');
var ajax = require('../ajax'),
    dialog = require('../dialog'),
    page = require('../page'),
    progress = require('../progress'),
    util = require('../util'),
    validator = require('../validator'),
    carousel = require('../carousel'),
    minicart = require('../minicart'),
    debounce = require('org_jau/frontend/utils').debounce;

var modules = {
    init: function () {
        initCharacterLimit();
        initSecondaryNavigation();
        initToggle();
        initPrivacyPolicy();
        initQuantity();
        initUnit();
        initSelectStyleEvents();
        initPasswordToggle();
        initFloatLabels();
        initSelfSlickingCarousels();
        initSelfLaunchingDialogs();
        initFooter();
        initLogin();
        initAddressForm();
        initDatePicker();
        initHighlightCreditCard();
        showPassField();
        closeSearchBar();
    }
};

/**
 * @function
 * @desc Initializes the textarea character limit functionality
 */
function initCharacterLimit() {
    var controlKeys = ['8', '9', '13', '46', '45', '36', '35', '38', '37', '40', '39'];

    $('body').on('keydown', 'textarea[data-character-limit]', function (e) {
        var text = $.trim($(this).val()),
            charsLimit = $(this).data('character-limit'),
            charsUsed = text.length;

        if ((charsUsed >= charsLimit) && (controlKeys.indexOf(e.which.toString()) < 0)) {
            e.preventDefault();
        }
    })
        .on('change keyup mouseup', 'textarea[data-character-limit]', function () {
            var text = $.trim($(this).val()),
                charsLimit = $(this).data('character-limit'),
                charsUsed = text.length;

            var charsRemain = charsLimit - charsUsed;

            if (charsRemain < 0) {
                $(this).val(text.slice(0, charsRemain));
                charsRemain = 0;
            }

            $(this).next('div.char-count').find('.char-remain-count').html(charsRemain);
        });

    // load js specific styles
    util.limitCharacters();
}

//Redirect Register user from tab
if ($('.page').data('querystring') === 'register=true') {
    $('a[href="' + '#register' + '"]').tab('show');
}

//Positioning change cep button
/*To-do:Move to other file */
if(!$('.postHeaderCostumer').length){
    $('.postcode-section-header.dropdown').css('margin-top','8px');
    $('.postcode-header-menu').css('left','199px');
}

//Show eye pass
function showPassField(){
    var pass = $('.loginForm');
    var magiceye = $('.magiceye');
    var close = $('.closeeye');

    magiceye.on('click',function(){
        var magiceye = $('.magiceye');
        var close = $('.closeeye')
        if(close.hasClass('d-none')){
            close.removeClass('d-none')
            magiceye.addClass('d-none')
            pass.attr('type','text');
        }
    });

    close.on('click',function(){
        if(magiceye.hasClass('d-none')){
            magiceye.removeClass('d-none')
            close.addClass('d-none')
            pass.attr('type','password')
        }
    });
}

/**
 * @function
 * @desc Initializes the secondary navigation functionality
 */
function initSecondaryNavigation() {
    // add show/hide navigation elements
    $('.secondary-navigation .toggle').click(function () {
        $(this).toggleClass('expanded').next('ul').toggle();
    });
}

//Redirect to Home
// if($('.page').data('action') === 'Sites-JauServe-Site'){
//     page.redirect(window.Urls.initialPage);
// }

//Get postalCode from Header to Mobile
if($('.user-postal-code').length) {
    $('.mobileCep').text($('.cepHeader').text());
}
/**
 * @function
 * @desc Initializes the generic toggle functionality
 */
function initToggle() {
    // add generic toggle functionality
    $('.toggle').next('.toggle-content').hide();

    $('.toggle').on('click', function () {
        if (!$(this).parents('.disable-toggle').length) {
            $(this).toggleClass('expanded').next('.toggle-content').toggle();
        }
    });
}

/**
 * @function
 * @desc Initializes the privacy policy functionality
 */
function initPrivacyPolicy() {
    $('.privacy-policy').on('click', function (e) {
        e.preventDefault();
        dialog.open({
            url: $(e.target).attr('href'),
            options: {
                height: 600
            }
        });
    });
}

/**
 * @function
 * @desc Initializes self-slicking carousels
 */
function initSelfSlickingCarousels() {

    //init each product tile carousel. look for class "tiles-carousel".
    var i = 0;
    $('.product-tile-carousel').each(function () {
        var uniqueClass = 'common-product-tile-carousel' + i++;
        $(this).addClass(uniqueClass);
        $('.' + uniqueClass).not('.slick-initialized').slick({
            config: carousel.landing
        });
    })

    //init each banner carousel. look for class "banner-carousel".
    i = 0;
    $('.banner-carousel').each(function () {
        var uniqueClass = 'common-banner-carousel' + i++;
        $(this).addClass(uniqueClass);
        var noLoop = $(this).hasClass('noloop');
        $('.' + uniqueClass).not('.slick-initialized').slick({
            config: carousel.hero,
            infinite: !noLoop
        });
    });
}

//Main Banner
$('.banner-carousel').not('.slick-initialized').slick(slickConfigs.hero);
/*
 * Close search bar
 */

function closeSearchBar(){
    $('.site-search').on('click', '.close-search', function(e){
        e.preventDefault();
        $('.suggestions').hide();
        $('.search-field').val('')
    });
}

/**
 * @function
 * @desc Initializes self-launching js dialog functionality: divs with class="open-dialog"
 *       plus either data-target="a-class-name" or an href.
 */
function initSelfLaunchingDialogs() {
    $('.open-dialog').on('click', function (e) {
        e.preventDefault();
        var target = $(e.target).data('target');
        var url = !target ? $(e.target).attr('href') : null;
        var options = {
            url: url,
            target: target
        };
        var data = $(e.target).data();
        for (var i in data) {
            options[i] = data[i];
        }
        dialog.open(options);
    });
}

const handleTooltip = debounce(
    function (e, qtyInputValue) {
        if (!$(e.target).length) return;
        if (!qtyInputValue) return;

        const currQty = parseInt(qtyInputValue, 10);

        if (currQty > 4) {
            const $qtyToolTipElem = $(e.target).parents('.quantity--wrapper').find('.quantity__tooltip');

            $qtyToolTipElem.removeClass('invisible').addClass('js-isActive');
            $qtyToolTipElem.trigger('tooltip:hide');
        }

        return this;
    }, 300
);

const triggerHideTooltip = function (ev) {
    $(ev.target).trigger('tooltip:hide');
};

const hideTooltip = debounce(
    function (ev) {
        const $evTarget = $(ev.target);
        if (!$evTarget.hasClass('js-isActive')) return;

        $evTarget.removeClass('js-isActive invisible');
    }, 3000
);

/**
 * @function
 * @desc Initializes the quantity module events and DOM changes
 */
function initQuantity() {
    let quantityChangeTimeout = null;

    /**
     * @listener
     * @desc Listens for the click and keyup events on the quantity decrease button
     */
    $('body').on('click keyup', '.quantity-decrease', function (e) {
        if (e.type === 'click' || (e.type === 'keyup' && e.which === 13)) {
            var $qtyModule = $(this).parents('.quantity-module'),
                $unitModule = $(this).parents('.module-qty-weight').find('.quantity-module-pdp'),
                $unitModuleContent = $('.module-qty-weight').find('.weight-unitcount'),
                $qtyInput = $qtyModule.find('input'),
                $unitInput = $unitModule.find('input.weight'),
                pid = $qtyModule.attr('data-product'),
                qtyInputValue = Number($qtyInput.val()),
                unitModuleInput = Number($unitModuleContent.val()),
                UUID = $qtyModule.attr('data-line-item');

            // Only decrease the value if the current value is greater than 1
            if (qtyInputValue > 1) {
                var $newqtyvalue = (qtyInputValue - 1),
                    $totalnew = ($newqtyvalue) * (unitModuleInput);
                if (UUID) {
                    $('.quantity-module[data-line-item="' + UUID + '"]').find('input').val(qtyInputValue - 1);
                    $unitInput.val($totalnew);
                } else {
                    $qtyInput.val(qtyInputValue - 1);
                    $unitInput.val($totalnew);
                }
                $qtyInput.trigger('change');
                $unitInput.val($totalnew);
                // Otherwise, remove the product from the cart
            } else {
                if ($(this).parents('#cart-items-form:not(.submitted)').length) {
                    $qtyInput.val(0)
                        .trigger('change');
                } else {
                    //Scroll Minicart Var - Remove Product By Quantity
                    var scrollPosition = $('.mini-cart-products').scrollTop();
                    $('.mini-cart-products').css('overflow','hidden');
                    $('.mini-cart-products').css('opacity','0.08');
                    var jqXHR = minicart.removeProduct(UUID, pid, $(this).parents('#mini-cart').length > 0);
                    //var productjqXHR = minicart.updateQuantity(UUID, pid,qtyInputValue,$(this).parents('#mini-cart').length > 0)

                    // eslint-disable-next-line no-inner-declarations
                    var updateChange = null;
                    window.clearTimeout(updateChange);
                    // eslint-disable-next-line no-redeclare
                    var updateChange = window.setTimeout(function(){
                        var productjqXHR = minicart.updateQuantity(UUID, pid,qtyInputValue,$(this).parents('#mini-cart').length > 0)

                        productjqXHR.done(function () {
                            // Update the product tile if one is available to update
                            var productTile = $('.product-options[data-item-uuid="' + UUID + '"]');

                            if (productTile.length) {
                                var productXHR = $.ajax({
                                    url: window.Urls.hitTile,
                                    data: {
                                        pid: pid
                                    }
                                });

                                productXHR.done(function (html) {
                                    var productTiles = $('.product-options[data-pid="' + pid + '"]').parents('.product-tile');
                                    productTiles.replaceWith(html);
                                });
                            }
                        }.bind(this))

                    }, 300);


                    jqXHR.done(function () {
                        //Scroll MiniCart - Remove Product by Quantity
                        $('.mini-cart-products').css('opacity','0.08');
                        $('.mini-cart-products').css('overflow','hidden');

                        setTimeout(function () {
                            $('.mini-cart-products').css('overflow','auto');
                        },300);

                        setTimeout(function () {
                            $('.mini-cart-products').scrollTop(scrollPosition);
                        },350);

                        setTimeout(function () {
                            $('.mini-cart-products').css('opacity','1');

                        },550);
                        // Update the product tile if one is available to update
                        var productTile = $('.product-options[data-item-uuid="' + UUID + '"]');

                        if (productTile.length) {
                            var productXHR = $.ajax({
                                url: window.Urls.hitTile,
                                data: {
                                    pid: pid
                                }
                            });

                            productXHR.done(function (html) {
                                var productTiles = $('.product-options[data-pid="' + pid + '"]').parents('.product-tile');
                                productTiles.replaceWith(html);
                            });
                        }
                    }.bind(this));
                }
            }

            qtyInputValue = (qtyInputValue - 1);

            handleTooltip(e, qtyInputValue);
        }
    });

    /**
     * @listener
     * @desc Listens for the click and keyup events on the quantity increase button
     */
    $('body').on('click keyup', '.quantity-increase:not(.disabled)', function (e) {
        if (e.type === 'click' || (e.type === 'keyup' && e.which === 13)) {
            var $qtyModule = $(this).parents('.quantity-module'),
                $unitModule = $(this).parents('.module-qty-weight').find('.quantity-module-pdp'),
                $unitModuleContent = $('.module-qty-weight').find('.weight-unitcount'),
                $qtyInput = $qtyModule.find('input'),
                $unitInput = $unitModule.find('input.weight'),
                pid = $qtyModule.attr('data-product'),
                $inputGroups = $('.quantity-module-pdp[data-product="' + pid + '"]').find('.input-group'),
                qtyInputValue = Number($qtyInput.val()),
                unitModuleInput = Number($unitModuleContent.val()),
                maxQtyValue = Number($qtyInput.attr('max'));

            // Only increase the value if the current value is less than the max value
            if (qtyInputValue < maxQtyValue) {
                var $newqtyvalue = (qtyInputValue + 1),
                    $totalnew = ($newqtyvalue) * (unitModuleInput);
                if (pid) {
                    $('.quantity-module[data-product="' + pid + '"]').find('input').val(qtyInputValue + 1);
                    $unitInput.val($totalnew);
                } else {
                    $qtyInput.val(qtyInputValue + 1);
                    $unitInput.val($totalnew);
                }
                $qtyInput.trigger('change');
                $unitInput.trigger('change');
            } else if (qtyInputValue == maxQtyValue) {
                $inputGroups.addClass('max-qty')
                    .find('.quantity-increase').addClass('disabled');
                setTimeout(function () {
                    $inputGroups.removeClass('max-qty');
                }, 1000);
            }

            qtyInputValue = (qtyInputValue + 1);

            handleTooltip(e, qtyInputValue);
        }
    });

    $('body').on('tooltip:hide', '.quantity__tooltip', hideTooltip);
    $('body').on('mouseenter', '.quantity--input', function (e) {
        const qtyInputValue = $(e.target).val();

        handleTooltip(e, qtyInputValue);
    });

    $('body').on('mouseleave', '.quantity--input', triggerHideTooltip);

    /**
     * @listener
     * @desc Listens for the change event on the quantity field
     */
    $('body').on('change', '.quantity-module input', function () {
        var $qtyModule = $(this).parents('.quantity-module'),
            $qtyInput = $qtyModule.find('input'),
            pid = $qtyModule.attr('data-product'),
            qtyValue = Number($(this).val()),
            maxQtyValue = Number($(this).attr('max'));

        if (qtyValue > maxQtyValue) {
            if (pid) {
                $('.quantity-module[data-product="' + pid + '"]').find('input').val(maxQtyValue);
            } else {
                $(this).val(maxQtyValue);
            }
        } else {
            $(this).val(qtyValue);
        }

        if (qtyValue <= 0) {
            if (pid) {
                $('.quantity-module[data-product="' + pid + '"]').find('input').val(0);
            } else {
                $(this).val(0);
            }
            if ($(this).parents('#cart-items-form:not(.submitted)').length) {
                $qtyInput.val(0)
                    .trigger('change');
            } else {
                var UUID = $(this).parents('.quantity-container').attr('data-item-uuid');

                var productjqXHR = minicart.updateQuantity(UUID, pid,qtyValue)
                var jqXHR = minicart.removeProduct(UUID, pid, $(this).parents('#mini-cart').length > 0);


                productjqXHR.done(function () {
                    // Update the product tile if one is available to update
                    var productTile = $('.product-options[data-item-uuid="' + UUID + '"]');

                    if (productTile.length) {
                        var productXHR = $.ajax({
                            url: window.Urls.hitTile,
                            data: {
                                pid: pid
                            }
                        });

                        productXHR.done(function (html) {
                            var productTiles = $('.product-options[data-pid="' + pid + '"]').parents('.product-tile');
                            productTiles.replaceWith(html);
                        });
                    }
                }.bind(this))

                jqXHR.done(function () {
                    // Update the product tile if one is available to update
                    var productTile = $('.product-options[data-item-uuid="' + UUID + '"]');
                    if (productTile.length) {
                        var productXHR = $.ajax({
                            url: window.Urls.hitTile,
                            data: {
                                pid: pid
                            }
                        });

                        productXHR.done(function (html) {
                            var productTiles = $('.product-options[data-pid="' + pid + '"]').parents('.product-tile');
                            productTiles.replaceWith(html);
                        });
                    }
                }.bind(this));


            }
        }
        $('.quantity-module[data-product="' + pid + '"]').find('.btn')
            .removeClass('disabled');
    });

    /**
     * @listener
     * @desc Listen for the change event triggered on the quantity input/select
     */
    $('body').off('change.quantity').on('change.quantity', '.quantity-module input, .quantity-module select', function (e) {
        var qtyOld = $(e.target).attr('value');
        window.clearTimeout(quantityChangeTimeout);
        var $product = $(e.target).parents('.quantity-container');
        var uuid = $product.attr('data-item-uuid');
        var pid = $product.attr('data-product') || $product.attr('data-itemid') || $product.attr('data-pid');
        var quantity = $(e.target).val();

        quantityChangeTimeout = window.setTimeout(function () {
            $product.addClass('disabled');
            $product.css('pointer-events','none');
            $product.css('opacity','0.7');
            //Scroll Minicart Quantity
            var scrollPosition = $('.mini-cart-products').scrollTop();
            $('.mini-cart-products').css('overflow','hidden');
            $('.mini-cart-products').css('opacity','0.08');
            var jqXHR = $.ajax({
                method: 'get',
                url: window.Urls.updateLineItemQuantity,
                data: {
                    uuid: uuid,
                    pid: pid,
                    quantity: quantity,
                    qtyOld: qtyOld,
                    miniCartAlter: $(e.target).parents('.mini-cart-content').length > 0 || $('.mini-cart-content').hasClass('mini-cart-show')
                }
            });

            progress.show('.mini-cart-content', false);

            jqXHR.done(function (html) {
                $product.addClass('disabled');
                $product.css('pointer-events','none');
                $product.css('opacity','0.7');

                setTimeout(function(){
                    $product.removeClass('disabled');
                    $product.css('opacity','1');
                    $product.css('pointer-events','auto');
                },500)

                if ($('.page').data('action') === 'Cart-Show') {
                    location.reload();
                    return;
                }
                if ($('#mini-cart').length) {
                    var minicart = require('../minicart');
                    minicart.show(html, $(e.target).parents('.promo-modal').length > 0 || $(e.target).parents('.card').length > 0 || !$('.mini-cart-empty').length, true);
                } else {
                    var transientUpdates = $(html).closest('script');
                    $('body').find('.transient-resources').replaceWith(transientUpdates);

                    var Promos = require('../promos');
                    Promos.addPromoMessages();
                    var minicart = require('../minicart'); // eslint-disable-line
                }

                // Trigger a mini cart update event for other parts of the site to listen for
                $(document).trigger({
                    type: 'basketupdate',
                    pid: pid,
                    quantity: quantity,
                    source: 'minicart',
                    uuid: uuid
                });
                var $qtyModule = $(this).parents('.quantity-module'),
                    $unitModule = $(this).parents('.module-qty-weight').find('.quantity-module-pdp'),
                    $unitModuleContent = $('.module-qty-weight').find('.weight-unitcount'),
                    $qtyInput = $qtyModule.find('input'),
                    $unitInput = $unitModule.find('input.weight'),
                    unitModuleInput = Number($unitModuleContent.val());
                //Scroll Minicart Quantity
                $('.mini-cart-products').css('opacity','0.08');
                $('.mini-cart-products').css('overflow','hidden');

                setTimeout(function () {
                    $('.mini-cart-products').css('overflow','auto');
                },300);

                setTimeout(function () {
                    $('.mini-cart-products').scrollTop(scrollPosition);
                },350);

                setTimeout(function () {
                    $('.mini-cart-products').css('opacity','1');

                },550);


                // Only decrease the value if the current value is greater than 1
                if (quantity >= 1) {
                    var $newqtyvalue = (quantity),
                        $totalnew = ($newqtyvalue) * (unitModuleInput);
                    if (uuid) {
                        $('.quantity-module[data-line-item="' + uuid + '"]').find('input').val(quantity);
                        $unitInput.val($totalnew);
                    } else {
                        $qtyInput.val(quantity);
                        $unitInput.val($totalnew);
                    }
                    $qtyInput.trigger('change');
                    $unitInput.val($totalnew);
                    // Otherwise, remove the product from the cart
                } else {
                    var minicart = require('../minicart'); // eslint-disable-line
                    if ($(this).parents('#cart-items-form:not(.submitted)').length) {
                        $qtyInput.val(0)
                            .trigger('change');
                    } else {
                        var UUID = $(this).parents('.quantity-container').attr('data-item-uuid');
                        var jqXHR = minicart.removeProduct(UUID, pid, $(this).parents('#mini-cart').length > 0);

                        jqXHR.done(function () {
                            //Scroll Minicart Quantity - RemoveProdQuanti
                            $('.mini-cart-products').css('opacity','0.08');
                            $('.mini-cart-products').css('overflow','hidden');

                            setTimeout(function () {
                                $('.mini-cart-products').css('overflow','auto');
                            },300);

                            setTimeout(function () {
                                $('.mini-cart-products').scrollTop(scrollPosition);
                            },350);

                            setTimeout(function () {
                                $('.mini-cart-products').css('opacity','1');

                            },600);
                            // Update the product tile if one is available to update
                            var productTile = $('.product-options[data-item-uuid="' + UUID + '"]');

                            if (productTile.length) {
                                var productXHR = $.ajax({
                                    url: window.Urls.hitTile,
                                    data: {
                                        pid: pid
                                    }
                                });

                                productXHR.done(function (html) {
                                    var productTiles = $('.product-options[data-pid="' + pid + '"]').parents('.product-tile');
                                    productTiles.replaceWith(html);
                                });
                            }
                        }.bind(this));


                    }
                }


            });

            // Make sure to remove the progress indicator after the Ajax call
            jqXHR.always(function () {
                progress.hide();
            });
        }, 300);
    });
}

function initUnit() {

    $('body').on('click keyup', '.remove-all-products-cart', function (e) {
        e.preventDefault();

        var productXHR = $.ajax({
            url: window.Urls.removeAllProductsFromCart,
        });

        productXHR.done(function () {
            window.location.reload();
        });
    });

    $('body').on('click keyup', '.remove-product-minicart', function (e) {
        e.preventDefault();
        var pid = $(this).attr('data-pid');
        var UUID = $(this).attr('data-uuid');

        //Scroll Minicart Var - Remove Product Button
        var scrollPosition = $('.mini-cart-products').scrollTop();
        $('.mini-cart-products').css('overflow','hidden');
        $('.mini-cart-products').css('opacity','0.08');

        var jqXHR = minicart.removeProduct(UUID, pid, $(this).parents('#mini-cart').length > 0);

        jqXHR.done(function () {

            //Scroll Minicart - Remove Product Button
            $('.mini-cart-products').css('opacity','0.08');
            $('.mini-cart-products').css('overflow','hidden');

            setTimeout(function () {
                $('.mini-cart-products').css('overflow','auto');
            },300);

            setTimeout(function () {
                $('.mini-cart-products').scrollTop(scrollPosition);
            },350);

            setTimeout(function () {
                $('.mini-cart-products').css('opacity','1');

            },550);

            // Update the product tile if one is available to update
            var productTile = $('.product-options[data-item-uuid="' + UUID + '"]');

            if (productTile.length) {
                var productXHR = $.ajax({
                    url: window.Urls.hitTile,
                    data: {
                        pid: pid
                    }
                });

                productXHR.done(function (html) {
                    var productTiles = $('.product-options[data-pid="' + pid + '"]').parents('.product-tile');
                    productTiles.replaceWith(html);
                });
            }
        }.bind(this));

    });
    $('body').on('click keyup', '.quantity-decrease-unit', function (e) {
        if (e.type === 'click' || (e.type === 'keyup' && e.which === 13)) {
            var $unitModule = $(this).parents('.quantity-module-pdp'),
                $qtyModule = $(this).parents('.module-qty-weight').find('.quantity-module'),
                $unitModuleContent = $('.module-qty-weight').find('.weight-unitcount'),
                $qtyInput = $qtyModule.find('input'),
                $unitInput = $unitModule.find('input.weight'),
                unitInputValue = Number($unitInput.val()),
                unitModuleInput = Number($unitModuleContent.val()),
                pid = $unitModule.attr('data-product');
            if (unitInputValue > unitModuleInput) {
                var $newqtyvalue = (unitInputValue - unitModuleInput),
                    $totalnew = ($newqtyvalue) / (unitModuleInput);
                if (pid) {
                    $qtyInput.val($totalnew);
                    $unitInput.val($newqtyvalue);
                } else {
                    $qtyInput.val($totalnew);
                    $unitInput.val($newqtyvalue);
                }
                $qtyInput.change();
            } else {
                if ($(this).parents('#cart-items-form:not(.submitted)').length) {
                    $unitInput.val(0).trigger('change');
                } else {
                    var minicart = require('../minicart');
                    var UUID = $(this).parents('.quantity-container').attr('data-item-uuid');
                    var productjqXHR = minicart.updateQuantity(UUID, pid, $qtyInput)
                    var jqXHR = minicart.removeProduct(UUID, pid, $(this).parents('#mini-cart').length > 0);


                    productjqXHR.done(function () {
                        // Update the product tile if one is available to update
                        var productTile = $('.product-options[data-item-uuid="' + UUID + '"]');

                        if (productTile.length) {
                            var productXHR = $.ajax({
                                url: window.Urls.hitTile,
                                data: {
                                    pid: pid
                                }
                            });

                            productXHR.done(function (html) {
                                var productTiles = $('.product-options[data-pid="' + pid + '"]').parents('.product-tile');
                                productTiles.replaceWith(html);
                            });
                        }
                    }.bind(this))

                    jqXHR.done(function () {
                        // Update the product tile if one is available to update
                        var productTile = $('.product-options[data-item-uuid="' + UUID + '"]');

                        if (productTile.length) {
                            var productXHR = $.ajax({
                                url: window.Urls.hitTile,
                                data: {
                                    pid: pid
                                }
                            });

                            productXHR.done(function (html) {
                                var productTiles = $('.product-options[data-pid="' + pid + '"]').parents('.product-tile');
                                productTiles.replaceWith(html);
                            });
                        }
                    }.bind(this));


                }
            }
        }
    });
    $('body').on('click keyup', '.quantity-increase-unit:not(.disabled)', function (e) {
        if (e.type === 'click' || (e.type === 'keyup' && e.which === 13)) {
            var $unitModule = $(this).parents('.quantity-module-pdp'),
                $qtyModule = $(this).parents('.module-qty-weight').find('.quantity-module'),
                $unitModuleContent = $('.module-qty-weight').find('.weight-unitcount'),
                $qtyInput = $qtyModule.find('input'),
                $unitInput = $unitModule.find('input.weight'),
                unitInputValue = Number($unitInput.val()),
                unitModuleInput = Number($unitModuleContent.val()),
                pid = $unitModule.attr('data-product'),
                $inputGroups = $('.quantity-module-pdp[data-product="' + pid + '"]').find('.input-group'),
                maxQtyValue = Number($unitInput.attr('max'));
            if (unitInputValue < maxQtyValue) {
                var $newqtyvalue = (unitInputValue + unitModuleInput),
                    $totalnew = ($newqtyvalue) / (unitModuleInput);
                if (pid) {
                    $qtyInput.val($totalnew);
                    $unitInput.val($newqtyvalue);
                } else {
                    $unitInput.val($newqtyvalue);
                    $qtyInput.val($totalnew);
                }
                $qtyInput.change();
                $unitInput.trigger('change');
            } else if (unitInputValue == maxQtyValue) {
                $inputGroups.addClass('max-qty')
                    .find('.quantity-increase-unit').addClass('disabled');
                setTimeout(function () {
                    $inputGroups.removeClass('max-qty');
                }, 1000);
            }
        }
    });


}


/**
 * @function
 * @desc Creates a 'focus' state for custom select boxes which is leverageable in CSS, and triggers validation on change
 * @returns
 */
function initSelectStyleEvents() {
    $('.select-style').on('focus', 'select', function () {
        $(this).parent().addClass('focus');
    }).on('blur', 'select', function () {
        $(this).parent().removeClass('focus');
    });
    $('.select-style').on('change', 'select', function () {
        if ($(this).closest('.jqvalidate').length > 0) {
            $(this).closest('.jqvalidate').validate().element($(this));
        }
    })
}

/**
 * @function
 * @desc Initializes password show toggle on inputs of type "password"
 * @returns
 */
function initPasswordToggle() {

    $(document).on('click', '.pwdshow', function () {
        var $input = $(this).siblings('input');

        var currentType = $input.attr('type');
        if (currentType == 'password') {
            $input.attr('type', 'text');
        } else if (currentType == 'text') {
            $input.attr('type', 'password');
        }

    });
}

/**
 * @function
 * @desc Initializes floating labels on form inputs (text and textarea only)
 * @returns
 */
function initFloatLabels() {
    /**
     * @function
     * @desc Updates CSS class on text fields to reflect whther they are populated with input or not
     */
    var pollInput = function ($el) {
        if ($el.val()) {
            $el.addClass('text-entered');
        } else {
            $el.removeClass('text-entered');
        }
    };

    // fire the pollInput function once initially on page load
    $(document).find('input, textarea').each(function () {
        pollInput($(this));
    });

    /**
     * @listener
     * @desc Fires the pollInput function once per textual input field every time a change is made
     */
    $(document).on('change keyup keypress input propertychange focus', 'input, textarea', function () {
        pollInput($(this));
    });

    /**
     * @listener
     * @desc Listens for window load and dyamically adds text-entered to any Chrome autofilled fields.
     */
    $(window).load(function () {
        try {
            $('input:-webkit-autofill').addClass('text-entered');
        } catch (error) {
            //hack not to show errors if not chrome
        }
    });
}

/**
 * @function
 * @desc Initializes the footer functionality
 */
function initFooter() {
    /**
     * @listener
     * @desc Listens for the click event on the footer column headers
     */
    $('.footer-column-header').on('click', function () {
        if (util.mediaBreakpointDown('md')) {
            if ($(this).siblings('.footer-links').is(':not(:visible)')) {
                $('.footer-column-header').removeClass('active');
                $('.footer-links:visible').slideUp();
            }

            $(this).siblings('.footer-links').slideToggle();
            $(this).toggleClass('active');
        }
    });

    /**
     * @listener
     * @desc Listens for the resize event on the window object
     */
    util.smartResize(function () {
        if (util.mediaBreakpointUp('md')) {
            $('.footer-links').removeAttr('style');
        }
    });
}

/**
 * @function
 * @desc Initializes the login form functionality in the header
 * @returns
 */
function initLogin() {
    var $container = $('.login-container');

    /**
     * @listener
     * @desc Listens for the click event on the password reset link
     * @returns
     */
    $('body').on('click.login', '.password-reset-link', function (e) {
        e.preventDefault();
        progress.show($container);
        var jqXHR = ajax.load({
            url: window.Urls.passwordWidget,
            target: $container
        });

        jqXHR.done(function () {
            validator.initForm($container.find('form'));
        });

        jqXHR.always(function () {
            progress.hide();
        });
    });

    /**
     * @listener
     * @desc Listens for the click event on the back to sign in link
     * @returns
     */
    $('body').on('click.login', '.back-to-signin', function (e) {
        e.preventDefault();
        progress.show($container);
        var jqXHR = ajax.load({
            url: window.Urls.loginWidget,
            target: $container
        });

        jqXHR.done(function () {
            validator.initForm($container.find('form'));
        });

        jqXHR.always(function () {
            progress.hide();
        });
    });

    /**
     * @listener
     * @desc Listens for the submit event on the popover login form
     * @returns
     */
    $('body').on('submit.login', '.login-container .login-form', function (e) {
        e.preventDefault();
        var $form = $(this);
        var formAction = $form.attr('action');
        let formData = $form.serialize();
        // Add the ajax formatting for the call
        formData += '&format=ajax';

        // Clear the form errors
        $form.find('.error').remove();

        var jqXHR = $.ajax({
            url: formAction,
            method: 'post',
            data: formData
        });

        jqXHR.done(function (response) {
            if (typeof response.data !== 'undefined' && response.data.code === 'success') {
                if ($('.login-container[data-target]').length && $('.login-container').attr('data-target').length) {
                    page.redirect($('.login-container').attr('data-target'));
                } else if ($('.login-container[data-wishListTarget]').length && $('.login-container').attr('data-wishListTarget').length) {
                    var $url = $('.login-container').attr('data-wishListTarget');
                    $.ajax({
                        url: $url,
                        method: 'GET',
                        dataType: 'json'
                    });
                    page.refresh();
                } else {
                    page.refresh();
                }
            } else if (typeof response.errors !== 'undefined' && response.errors.length) {
                var errorMsg = response.errors[0].code;
                $form.find('.input-text').removeClass('valid').addClass('error');
                $form.find('input[type="password"] + label').after('<span class="error">' + errorMsg + '</span>');
            } else if (typeof response.error !== 'undefined') {
                var errorMessage = response.error;
                $form.find('.input-text').removeClass('valid').addClass('error');
                $form.find('input[type="password"] + label').after('<span class="error">' + errorMessage + '</span>');

                if (errorMessage === 'CSRF Token Mismatch') {
                    page.refresh();
                }
            }
        });
    });

    /**
     * @listener
     * @desc Listens for the submit event on the popover login form
     * @returns
     */
    $('body').on('submit.login', '.login-container .password-reset-form', function (e) {
        e.preventDefault();
        var $form = $(this);
        var formAction = $form.attr('action');
        let formData = $form.serialize();
        // Add the ajax formatting for the call
        formData += '&format=ajax';

        // Clear the form errors
        $form.find('.error').remove();

        progress.show($container);

        let jqXHR = $.ajax({
            url: formAction,
            method: 'post',
            data: formData
        });

        jqXHR.done(function (response) {
            if (typeof response.data !== 'undefined' && response.data.code === 'success') {
                progress.show($container);

                jqXHR = ajax.load({
                    url: window.Urls.passwordConfirmWidget,
                    target: $container
                });

                jqXHR.always(function () {
                    progress.hide();
                });
            } else if (typeof response.errors !== 'undefined' && response.errors.length) {
                var errorMsg = response.errors[0].code;
                $form.find('input[type="text"]').removeClass('valid').addClass('error');
                $form.find('input[type="text"] + label').after('<span class="error" id="' + $form.find('input[type="text"]').attr('id') + '-error">' + errorMsg + '</span>');
                $form.parents('.password-reset-container')
                    .siblings('.forgotten-password').show();
            } else if (typeof response.error !== 'undefined') {
                var errorMessage = response.error;
                $form.find('input[type="password"] + label').after('<span class="error">' + errorMessage + '</span>');

                if (errorMessage === 'CSRF Token Mismatch') {
                    page.refresh();
                }
            }
        });

        jqXHR.always(function () {
            progress.hide();
        });
    });

    // Hide the login welcome after 5 seconds if it is currently present on the page
    if ($('.login-welcome').length) {
        window.setTimeout(function () {
            $('.login-welcome').slideUp();
        }, 5000);
    }
}

/**
 * @function
 * @desc Initializes the address form functionality throughout the site
 * @returns
 */
function initAddressForm() {
    /**
     * @listener
     * @desc Listens for the blur event on the postal code address form field
     */
    $('body').on('blur', '.address-form .postal', function () {
        var postalCode = $(this).val(),
            validField = $(this).valid();

        if (postalCode && validField) {
            var jqXHR = $.ajax({
                url: window.Urls.getDeliveryStatus,
                data: {
                    code: postalCode
                }
            });

            jqXHR.done(function (response) {
                $('#postal-code ~ .delivery-status').remove();

                if (response.data) {
                    if (response.data.code === true) {
                        $('#postal-code').parents('.field-wrapper').append('<div class="form-sub-caption delivery-status available">' + window.Iconography.check_solid + window.Resources.ADDRESS_DELIVERY_AVAILABLE + '</div>');
                    } else {
                        $('#postal-code').parents('.field-wrapper').append('<div class="form-sub-caption delivery-status unavailable">' + window.Iconography.times_solid + window.Resources.ADDRESS_DELIVERY_UNAVAILABLE + '</div>');
                    }

                    if ($('.address-information').is(':not(:visible)')) {
                        $('.address-information').slideDown();
                    }
                } else {
                    window.console.error(response.errors[0].code);
                }
            });

            // TODO: retrieve the available addresses with PCA Predict
        } else {
            $('#postal-code ~ .delivery-status').remove();
        }
    });

    /**
     * @listener
     * @desc Listens for the change event on the postal code field
     */
    $('body').on('input', '.address-form .postal', function () {
        var $postalFields = $('.address-form .postal');
        $postalFields.val($(this).val());
        $postalFields.trigger('change');
    });

    /**
     * @listener
     * @desc Listens for the click event on the manual address edit link
     */
    $('body').on('click', '.address-form .manual-edit', function () {
        if ($('.address-information').is(':not(:visible)')) {
            $('.address-information').slideDown();
        }
    });

    if (typeof pca !== 'undefined' && typeof pca.on === 'function') { // eslint-disable-line
        /**
         * Listens for the data event from PCA and triggers form field change events when an address is populated
         * @param source
         * @param key
         * @returns
         */
        pca.on('data', function (source, key) { // eslint-disable-line
            if (source === 'address') {
                for (var i = 0; i < pca.platform.productList[key].PLATFORM_CAPTUREPLUS.bindings[0].fields.length; i++) { // eslint-disable-line
                    var pcaField = document.getElementById(pca.platform.productList[key].PLATFORM_CAPTUREPLUS.bindings[0].fields[i].element); // eslint-disable-line
                    if (pcaField) {
                        pca.fire(pcaField, 'change'); // eslint-disable-line
                        $(pcaField).valid();
                        $(pcaField).trigger('blur');
                    }
                }
            }
        });

        pca.on('load', function (type, id, control) { // eslint-disable-line
            if (type === 'capture+') {
                control.listen('results', function () {
                    if (control.autocomplete.field) {
                        $(control.autocomplete.field).valid();
                    }
                });
            }
        });

        if (typeof pca.load === 'function') { // eslint-disable-line
            pca.load(); // eslint-disable-line
        }
    }
}

/*
 * @desc Initializes the generic UI date picker
 */
function initDatePicker() {
    // define default datepicker config
    var defaultOptions = {
        dateFormat: 'dd/mm/yy',
        changeYear: true,
        afterShow: function () { // this is a custom event. see jquery-ext.js
            $('select.ui-datepicker-year').wrap('<div class="select-style"></div>');
        }
    };

    // add generic datepicker
    $('.input-date').datepicker(defaultOptions);

    // add date or birth datepickers
    $('.input-date-dob').datepicker($.extend({}, defaultOptions, {
        maxDate: '-18y',
        yearRange: '1900:-18y'
    }));

    $('.dateshow').on('click', function () {
        $(this).siblings('.input-date').focus();
    });
}

/**
 * @function
 * @desc Highlight credit card number
 */
function initHighlightCreditCard() {

    $('body').on('change', '.ccard-number', function () {
        $(this).parents('.field-wrapper')
            .removeClass('highlighted');

        if ($(this).valid()) {
            $(this).parents('.field-wrapper')
                .addClass('highlighted');
        }
    });
}

module.exports = modules;
