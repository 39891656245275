'use strict';

const {isEmpty} = require('lodash');

/**
 * appends params to a url
 * @param {string} data - data returned from the server's ajax call
 * @param {Object} icon - icon that was clicked to add a product to the wishlist
 */
function displayMessageAndChangeIcon(data, icon) {
    $.spinner().stop();
    var status;
    var wishlistTile = icon.closest('.wishlistTile');

    if (data.success) {
        status = 'alert-success';
        if (icon.hasClass('fa-heart-o') && wishlistTile.hasClass('added-to-wishlist')) {
            icon.removeClass('fa-heart-o').addClass('fa-heart');
            $('.favouritesTitle').text(window.Resources.REMOVE_PRODUCT_PDP_FAVOURITES);
        } else if (icon.hasClass('fa-heart') && !wishlistTile.hasClass('added-to-wishlist')) {
            icon.removeClass('fa-heart').addClass('fa-heart-o');
            $('.favouritesTitle').text(window.Resources.ADD_PRODUCT_PDP_FAVOURITES);
        }
    } else {
        status = 'alert-danger';
    }
    if ($('.add-to-wishlist-messages').length === 0) {
        $('body').append(
            '<div class="add-to-wishlist-messages "></div>'
        );
    }
    $('.add-to-wishlist-messages')
        .append('<div class="add-to-wishlist-alert text-center ' + status + '">' + data.msg + '</div>');
    setTimeout(function () {
        $('.add-to-wishlist-messages').remove();
    }, 5000);
}

function fillHearts(){
    var prodsFromSession = JSON.parse(window.localStorage.getItem('wishlists_products'));
    //var prodTile = $('.product-tile').attr('data-itemid');
    var wishProds;

    for(var i = 0; i<prodsFromSession.length; i++){
        wishProds = prodsFromSession[i].pid;
        var productTileWish =  $('.product-tile[data-itemid="' + wishProds + '"]')
        var childWish = $(productTileWish).find('.wishlistTile');
        var iconChild = $(childWish).find('i')
        
        if(productTileWish.length > 0) {
            var wishTest = !$(productTileWish).children('.wishlistTile').hasClass('added-to-wishlist') ? true:false;
            if(wishTest){
                $(iconChild).removeClass('fa-heart-o').addClass('fa-heart');
                $(childWish).addClass('added-to-wishlist');
            }
        }
    }

 
}


function fillWishlist(){
    $.spinner().start();
    $.ajax({
        url:'Wishlist-GetListJson',
        type:'get',
        dataType:'json',
        success:function(data){
            window.localStorage.setItem('wishlists_products',JSON.stringify(data.list.items));
            fillHearts();
            $.spinner().stop();
        },
        error: function() {
            //displayMessageAndChangeIcon(error, icon);
            $.spinner().stop()
        }
    })

}


module.exports = {
    addToWishlist: function () {
        $('body').on('click', '.wishlistTile', function (e) {
            e.preventDefault();

            var icon = $(this).find($('i'));
            var url = $(this).attr('href');
            var pid = $(this).closest('.grid-tile').data('pid') || $(this).closest('.pdp-main').data('pid');
            var optionId = $(this).closest('.product-detail').find('.product-option').attr('data-option-id');
            var optionVal = $(this).closest('.product-detail').find('.options-select option:selected').attr('data-value-id');
            optionId = optionId || null;
            optionVal = optionVal || null;

            if (!$(this).hasClass('added-to-wishlist')) {
                // product not in wishlist - add it
                if (!url || !pid) {
                    return;
                }

                $.spinner().start();
                $.ajax({
                    url: url,
                    type: 'post',
                    dataType: 'json',
                    context: this,
                    data: {
                        pid: pid,
                        optionId: optionId,
                        optionVal: optionVal
                    },
                    success: function (data) {
                        $(this).addClass('added-to-wishlist');
                        $(this).attr('href', $(this).data('remove-url'));
                        displayMessageAndChangeIcon(data, icon);
                        if(data.list) window.localStorage.setItem('wishlists_products',JSON.stringify(data.list.items));
                       // fillWishlist();
                    },
                    error: function (err) {
                        var data = err.responseJSON;
                        if (data.redirectUrl) {
                            window.location.href = data.redirectUrl;
                        }
                        displayMessageAndChangeIcon(err, icon);
                    }
                });
            } else {
                // product already in wishlist - remove it
                $.spinner().start();
                $.ajax({
                    url: url,
                    type: 'get',
                    dataType: 'json',
                    context: this,
                    data: {
                        pid: pid,
                        optionId: optionId,
                        optionVal: optionVal
                    },
                    success: function (data) {
                        $(this).removeClass('added-to-wishlist');
                        $(this).attr('href', $(this).data('add-url'));
                        displayMessageAndChangeIcon(data, icon);
                        if(data.list) window.localStorage.setItem('wishlists_products',JSON.stringify(data.list.items));
                        if ($('.favourites-wrapper').length > 0) {
                            location.reload();
                        }
                        //fillWishlist();
                    },
                    error: function (err) {
                        var data = err.responseJSON;
                        if (data.redirectUrl) {
                            window.location.href = data.redirectUrl;
                        }
                        displayMessageAndChangeIcon(err, icon);
                    }
                });
            }
        });
    },

    callWishlist: function(){
        var wishProduct = JSON.parse(window.localStorage.getItem('wishlists_products'));
        //All the products from grid Tile will be empty on begginer
        $('.wishlistTile').removeClass('added-to-wishlist');
        $('.wishlistTile').find('i').removeClass('fa-heart').addClass('fa-heart-o');

        if ($('.user-signin').is(':visible')) {
            window.localStorage.setItem('wishlists_products',JSON.stringify({}));       
        } else{
            if (isEmpty(wishProduct)){
                fillWishlist();
            }
        }
        fillHearts();
    }
};
