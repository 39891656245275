'use strict';

class Modal extends HTMLElement {
    constructor() {
        super();
        this._modalVisible = false;
        this._modal;
        var title=$('.simple-modal .header')[0].innerHTML;
        var text=$('.simple-modal .body')[0].innerHTML;

        $('.simple-modal')[0].innerHTML=`
        <div class="modal fade" id="sellerInfoModal" tabindex="-1" role="dialog" aria-labelledby="sellerInfoModalLabel" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        ${title}
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        ${text}
                    </div>
                </div>
            </div>
        </div>
        `
    }
    connectedCallback() {
        this._modal = document.querySelector(".modal");

        document.querySelector(".modal .close").addEventListener('click', this._hideModal.bind(this));
    }
    disconnectedCallback() {
        document.querySelector(".modal .close").removeEventListener('click', this._hideModal);
    }
    _showModal() {
        this._modalVisible = true;
        this._modal.style.display = 'block';
    }
    _hideModal() {
        this._modalVisible = false;
        this._modal.style.display = 'none';
    }
}

customElements.define('simple-modal',Modal);