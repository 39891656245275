'use strict';
var util = require('grocery/util');
var keyboardAccessibility = require('base/components/keyboardAccessibility');

var clearSelection = function (element) {
    $(element).closest('.dropdown').children('.dropdown-menu').children('.top-category')
        .detach();
    $(element).closest('.dropdown.show').children('.nav-link').attr('aria-expanded', 'false');
    $(element).closest('.dropdown.show').removeClass('show');
    $(element).closest('li').detach();
};

var searchField = {
    expanded: false,
    selector: $('body').find('.site-search'),

    clear: function (selector) {
        var currentSelector = selector || this.selector;
        currentSelector.find('input').val('');
        // searchsuggest.clearResults();
    },

    focus: function (selector) {
        var currentSelector = selector || this.selector;
        currentSelector.find('input').first().focus();
    },

    collapseSearchField: function () {
        this.selector.removeClass('expand').attr('aria-expanded', 'false');
        $('.page').removeClass('expanded-search');
        this.expanded = false;
    },

    expandSearchField: function () {
        this.selector.addClass('expand').attr('aria-expanded', 'true');
        this.focus();
        $('.page').addClass('expanded-search');
        this.expanded = true;
    }
};

var removeNavbarOpenedState = function() {
    $('body').on('mousedown', '.modal-menu-background', function() {
        $('body').removeClass('navbar--opened');
    });
};

module.exports = function () {
    removeNavbarOpenedState();

    var isDesktop = function () {
        if (util.mediaBreakpointUp('lg')) {
            return false;
        } else {
            return true;
        }
    };

    $('.header-banner .close').on('click', function () {
        $('.header-banner').addClass('hide');
    });

    keyboardAccessibility('.main-menu .nav-link, .main-menu .dropdown-link',
        {
            40: function (menuItem) { // down
                if (menuItem.hasClass('nav-item')) { // top level
                    $('.navbar-nav .show').removeClass('show')
                        .children('.dropdown-menu')
                        .removeClass('show');
                    menuItem.addClass('show').children('.dropdown-menu').addClass('show');
                    $(this).attr('aria-expanded', 'true');
                    menuItem.find('ul > li > a')
                        .first()
                        .focus();
                } else {
                    menuItem.removeClass('show').children('.dropdown-menu').removeClass('show');
                    $(this).attr('aria-expanded', 'false');
                    menuItem.next().children().first().focus();
                }
            },
            39: function (menuItem) { // right
                if (menuItem.hasClass('nav-item')) { // top level
                    menuItem.removeClass('show').children('.dropdown-menu').removeClass('show');
                    $(this).attr('aria-expanded', 'false');
                    menuItem.next().children().first().focus();
                } else if (menuItem.hasClass('dropdown')) {
                    menuItem.addClass('show').children('.dropdown-menu').addClass('show');
                    $(this).attr('aria-expanded', 'true');
                    menuItem.find('ul > li > a')
                        .first()
                        .focus();
                }
            },
            38: function (menuItem) { // up
                if (menuItem.hasClass('nav-item')) { // top level
                    menuItem.removeClass('show').children('.dropdown-menu').removeClass('show');
                    $(this).attr('aria-expanded', 'false');
                } else if (menuItem.prev().length === 0) {
                    menuItem.parent().parent().removeClass('show')
                        .children('.nav-link')
                        .attr('aria-expanded', 'false');
                    menuItem.parent().parent().children().first()
                        .focus();
                } else {
                    menuItem.prev().children().first().focus();
                }
            },
            37: function (menuItem) { // left
                if (menuItem.hasClass('nav-item')) { // top level
                    menuItem.removeClass('show').children('.dropdown-menu').removeClass('show');
                    $(this).attr('aria-expanded', 'false');
                    menuItem.prev().children().first().focus();
                } else {
                    menuItem.closest('.show').removeClass('show')
                        .closest('li.show').removeClass('show')
                        .children()
                        .first()
                        .focus()
                        .attr('aria-expanded', 'false');
                }
            },
            27: function (menuItem) { // escape
                var parentMenu = menuItem.hasClass('show')
                    ? menuItem
                    : menuItem.closest('li.show');
                parentMenu.children('.show').removeClass('show');
                parentMenu.removeClass('show').children('.nav-link')
                    .attr('aria-expanded', 'false');
                parentMenu.children().first().focus();
            }
        },
            function () {
                return $(this).parent();
            }
        );

    $('.dropdown:not(.disabled):not(.mobile-account-menu-wrapper) [data-toggle="dropdown"]')
        .on('click', function (e) {
            e.preventDefault();
            $(this).parent().siblings().removeClass('show');
            if ($(this).parent().hasClass('show')) {
                $(this).parent().removeClass('show');
                $(this).attr('aria-expanded', 'false');
            } else {
                $(this).parent().addClass('show');
                $(this).attr('aria-expanded', 'true');

            }
        });

    $('.dropdown.mobile-account-menu-wrapper [data-toggle="dropdown"]')
        .on('click', function (e) {
            $(this).parent().toggleClass('show');
            $(this).attr('aria-expanded', 'true');
            e.preventDefault();
        });
    
    if($('.page').data('action') === 'Search-Show') {
        var navItem = $('.nav-item.dropdown');
        var dropdownItem = $('.dropdown-item');
        var catSearch = ($('.header.page-title').text().trim()).toString()
        
        for (var i = 0; i < dropdownItem.length; i++) {
            if(($(dropdownItem[i]).children('a').text()).toString() == catSearch) {
                var catParent = $(dropdownItem[i]).parents('.nav-item.dropdown');
        
                catParent.toggleClass('show')
                catParent.children('.nav-link').attr('aria-expanded', 'true')
                    //catlink.attr('aria-expanded', 'true')
            }
        
            if(($(navItem[i]).children('a').text()).toString() == catSearch) {
                $(navItem[i]).toggleClass('show');
                $(navItem[i]).children('.nav-link').attr('aria-expanded', 'true')
            }
        } 
            
    }

    $('.navbar > .close-menu > .close-button, .close-menu .close-button').on('click', function (e) {
        e.preventDefault();
        $('.menu-toggleable-left').removeClass('in');
        $('.navbar-toggler').removeClass('show');
        $('body').removeClass('navbar--opened');
        $('.modal-menu-background').hide();
    });

    $('.navbar-nav .dropdown-menu').on('click', '.back', function (e) {
        e.preventDefault();
        clearSelection(this);
    });

    $('.navbar-nav').on('click', '.close-button', function (e) {
        e.preventDefault();
        $('.navbar-nav').find('.top-category').detach();
        $('.navbar-nav').find('.nav-menu').detach();
        $('.navbar-nav').find('.show').removeClass('show');
        $('.menu-toggleable-left').removeClass('in');
        $('.navbar-toggler').removeClass('show');
        $('body').removeClass('navbar--opened');
        $('.modal-menu-background').hide();
    });

    $('.navbar-toggler').click(function (e) {
        e.preventDefault();
        $('.main-menu').toggleClass('in');
        $('.navbar-toggler').toggleClass('show');
        $('body').addClass('navbar--opened');
        $('.modal-menu-background').toggle();
    });

    $(document).on('click', function (e) {
        if (!isDesktop()) {
            util.clickOutside($('nav[role="navigation"]'), function () {
                setTimeout(function () {
                    e.preventDefault();

                    if (!$(e.target).hasClass('caret-left')) {
                        $('.menu-toggleable-left').removeClass('in');
                        $('.navbar-toggler').removeClass('show');
                        $('.modal-menu-background').hide();
                    }
                }, 200);
            });
        }
    });

    if (isDesktop()) {
        $('.modal-menu-background').click(function (e) {
            e.preventDefault();
            $('.main-menu').toggleClass('in');
            $('.navbar-toggler').toggleClass('show');
            $('body').removeClass('navbar--opened');
            $('.modal-menu-background').toggle();
        });
    }

    keyboardAccessibility('.navbar-header .user',
        {
            40: function ($popover) { // down
                if ($popover.children('a').first().is(':focus')) {
                    $popover.children('a').first().next().focus();
                } else {
                    $popover.children('a').first().focus();
                }
            },
            38: function ($popover) { // up
                if ($popover.children('a').first().is(':focus')) {
                    $(this).focus();
                    $popover.removeClass('show');
                } else {
                    $popover.children('a').first().focus();
                }
            },
            27: function ($popover) { // escape
                $(this).focus();
                $popover.removeClass('show');
            },
            9: function ($popover) { // tab
                $popover.removeClass('show');
            }
        },
        function () {
            var $popover = $('.user .popover');
            if (!($popover.hasClass('show'))) {
                $popover.addClass('show');
            }
            return $popover;
        }
    );

    $('.navbar-header .user').on('mouseenter focusin', function () {
        if ($('.navbar-header .user .popover').length > 0) {
            $('.navbar-header .user .popover').addClass('show');
        }
    });

    $('.navbar-header .user').on('mouseleave', function () {
        $('.navbar-header .user .popover').removeClass('show');
    });


    /**
     * @listener
     * @desc Listens for click on the mobile search icon and backarrow and expands / collapses header search (in mobile only)
     */
    $('.header-expand-search, .site-search .backarrow').on('click', function () {
        if (searchField.expanded) {
            searchField.collapseSearchField();
            searchField.clear();
        } else {
            searchField.expandSearchField();
        }
    });
};
