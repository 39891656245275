'use strict';

var $ = require('jquery')

module.exports = {
    toggleClass: () => {
        $('.change-delivery-type').on('click', function () {
            $(this).toggleClass('active')
        });
    }
}
