'use strict';
// function getCookie(name) {
//     const value = `; ${document.cookie}`;
//     const parts = value.split(`; ${name}=`);
//     if (parts.length === 2) return parts.pop().split(';').shift();
// }
/**
 * Renders a modal window that will track the users consenting to accepting site tracking policy
 */
function showConsentModal() {
    if (!$('.tracking-consent').data('caonline')) {
        return;
    }

    var urlContent = $('.tracking-consent').data('url');
    /* var urlAccept = $('.tracking-consent').data('accept');
    var urlReject = $('.tracking-consent').data('reject'); */

    var htmlString = '<div class="cookie-hint-dialog" style="display: none;"><a class="accept-policy" href="#"></a></div>';
    $('body').append(htmlString);

    $.ajax({
        url: urlContent,
        type: 'get',
        dataType: 'html',
        success: function (response) {
            $('.cookie-hint-dialog').html(response);

            // if (!window.Resources.userLoggedIn || !getCookie('dw_shippostalcode')) {
            //     $('.cookie-hint-dialog').show();
            // } else {
            //     $('.cookie-hint-dialog').remove();
            // }
        },
        error: function () {
            $('.cookie-hint-dialog').remove();
        }
    });

    $('.cookie-hint-dialog').on('click', 'a.accept-policy' ,function (e) {
        e.preventDefault();
        var url = $(this).attr('href');
        $.ajax({
            url: url,
            type: 'get',
            dataType: 'json',
            success: function () {
                $('.cookie-hint-dialog').remove();
            },
            error: function () {
                $('.cookie-hint-dialog').remove();
            }
        });
    });
}

module.exports = function () {
    if ($('.consented').length === 0 && $('.tracking-consent').hasClass('api-true')) {
        showConsentModal();
    }

    if ($('.tracking-consent').hasClass('api-true')) {
        $('.tracking-consent').click(function () {
            showConsentModal();
        });
    }
};
