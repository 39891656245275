'use strict';

var util = require('../util'),
    dialog = require('../dialog'),
    page = require('../page');

function initializeOrderEditCancelModal () {
    var $form = $('#cancel-orderedit-flow');
    $form.on('click', 'button[name="cancel-no"]', function (e) {
        e.preventDefault();
        dialog.close();
    })
    .on('click', 'button[name="cancel-yes"]', function (e) {
        e.preventDefault();
        $.ajax({
            url: util.appendParamToURL(Urls.cancelOrderEditFlow, 'format', 'ajax'),
            method: 'POST',
            dataType: 'json'
        }).done(function (response) {
            if (typeof response === 'object' && response.data !== undefined && response.data.code == 'success') {
                dialog.close();
                page.refresh();
            }
        });
    });
}

function initializeOrderCancelModal() {
    var $form = $('#cancel-order');
    $form.on('click', '.cancel-no', function (e) {
        e.preventDefault();
        dialog.close();
    })
    .on('click', '.cancel-yes', function (e) {
        e.preventDefault();
        $.ajax({
            url: util.appendParamToURL(Urls.cancelOrder, 'orderNumber', $(this).data('orderid')),
            method: 'POST',
            dataType: 'json'
        }).done(function (response) {
            dialog.open({
                url: util.appendParamToURL(Urls.cancelOrderConfirmationModal, 'status', response.data.success),
                options: {
                    open: function () {
                        var $form = $('#cancel-confirmation');
                        $form.on('click', '.finish', function (e) {
                            e.preventDefault();
                            dialog.close();
                            page.redirect(Urls.orderHistory);
                        })
                    }
                }
            });
        });
    });
}


function initializeEvents() {
    $('.cancel-order-edit-cart').on('click', function (e) {
        e.preventDefault();
        dialog.open({
            url: Urls.cancelOrderEditModal,
            options: {
                open: initializeOrderEditCancelModal,
                width: 650,
                dialogClass: 'modal-lg basket-dialog'
            }
        });
    });

    $('.cancel-order').on('click', function (e) {
        e.preventDefault();
        dialog.open({
            url: util.appendParamToURL(Urls.cancelOrderModal, 'orderid', $(this).data('orderid')),
            options: {
                open: initializeOrderCancelModal,
                width: 650,
                dialogClass: 'modal-lg basket-dialog'
            }
        });
    })
}

var deliveryorder = {
    init: function () {
        initializeEvents();
    }
};

module.exports = deliveryorder;
