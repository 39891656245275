'use strict';

var debounce = require('../frontend/utils').debounce;

const returnedModule = {
    getCarouselData: function () {
        return window.carouselLoadedObj;
    },

    removeLoadingEffect: debounce(
        function (target) {
            if (!$(target).length) return;

            $(target).spinner().stop();

            return this;
        }, 1000
    ),

    hideCarouselShelf: debounce(
        function (ev, data) {
            const carouselData = data || _self.getCarouselData();

            if (!carouselData) return;

            $(carouselData).each(function (idx, currValue) {
                var qtdProdOnStock = currValue.qtdProdOnStock;

                if (qtdProdOnStock <= 0) {
                    $(currValue.target).hide();
                }

                _self.removeLoadingEffect(currValue.target);

            });
        }, 1000
    )
};

const _self = returnedModule;

module.exports = returnedModule;
