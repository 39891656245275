'use strict'

window.jQuery = window.$ = require('jquery');

var processInclude = require('base/util');

window.$(document).ready(function () {
    processInclude(require('./components/menu'));
    processInclude(require('base/components/cookie'));
    processInclude(require('grocery/components/consentTracking'));
    processInclude(require('grocery/components/header'));
    processInclude(require('org/components/footer'));
    processInclude(require('lyonscg/components/backtotop'));
    processInclude(require('base/components/collapsibleItem'));
    processInclude(require('base/components/search'));
    processInclude(require('base/components/clientSideValidation'));
    processInclude(require('base/components/countrySelector'));
    processInclude(require('./components/carousels'));
    processInclude(require('lyonscg/components/tooltips'));
    processInclude(require('./delivery'));
    processInclude(require('grocery/components/modules'));
    processInclude(require('grocery/deliveryorder'));
    processInclude(require('./product/wishlistHeart'));
    processInclude(require('grocery/product-tile'));
    processInclude(require('./components/changedIdea'));
    processInclude(require('plugin_marketplace_storefront/components/simple-modal/simple-modal'));

});

// jQuery plugins

require('jquery-migrate');
require('jquery-validation');
require('jquery-ui/ui/widgets/dialog');
require('jquery-ui/ui/widgets/datepicker');
require('jquery-mask-plugin');

require('./thirdParty/bootstrap');
require('./components/spinner');
require('svg4everybody');
require('slick-carousel');
