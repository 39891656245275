
'use strict';

var miniCart = require('../minicart');
var formValidation = require('base/components/formValidation');
var baseLogin = require('base/login/login');
baseLogin.login = function() {};
baseLogin.resetPassword = function() {};


module.exports = {

    init: function() {
        miniCart.init();
    },
    headerSignIn: function() {
        $(document).on('click', '.user-signin, .login-section-header.dropdown', function (e) {
            e.preventDefault();
            $(this).toggleClass('in');
            $('.account-header-menu').toggleClass('show');
            $('header').toggleClass('show-user-login');
            $('.mobile-account-header-menu').toggleClass('show');
            $('.header-menu-modal-shiv').toggle(); // Open or Close user menu

            //CSS Behavior on Header
            if($('.login-section-header.alt.dropdown').hasClass('in')){
                $('.account-header-menu').css({'margin-top':'20px', 'right':'34px', 'max-width':'200px'});
            }else {
                $('.user-postal-code').css('padding-top','8px');
            }
        });

        $(document).on('click', '.postcode-section-header.dropdown', function (e) {
            e.preventDefault();
            $(this).toggleClass('in');
            $('.postcode-header-menu').toggleClass('show');
           // $('header').toggleClass('show-user-postalcode');
            $('.header-menu-modal-shiv').toggle();
        })

        $(document).on('click', '.user-message', function (e) {
            e.preventDefault();
            $(this).toggleClass('in');
            $('.account-header-menu').toggleClass('show');
            $('.mobile-account-header-menu').toggleClass('show');
            $('.modal-menu-background').toggle();
        });
        $(document).on('click', '.header-menu-modal-shiv', function (e) {
            e.preventDefault();
            $('header').removeClass('show-user-login'); // Close user menu
            $('.user-signin, .login-section-header.dropdown').removeClass('in'); // Close user menu
            $('.account-header-menu').removeClass('show');
            $('.mobile-account-header-menu').removeClass('show');
            $('.password-reset-menu').removeClass('show');
            $('.header-menu-modal-shiv').hide(); // Close user menu
        });
        // If they click the signin after it's open just close all the windows
        $(document).on('click', '.user-signin.in', function (e) {
            e.preventDefault();
            $('.account-header-menu').removeClass('show');
            $('.mobile-account-header-menu').removeClass('show');
            $('.password-reset-menu').removeClass('show');
        })
        $(document).on('click', '.close-login-button', function (e) {
            e.preventDefault();
            $('.mobile-account-header-menu').removeClass('show');
        })
        $(document).off('click', '.change-postal-code-header').on('click', '.change-postal-code-header', function (e) {

            e.preventDefault();
            var $link = $(this);
            $.ajax({
                type: 'POST',
                url: $link.attr('href'),
                success: function () {
                    window.location.reload();
                }
            });
        });
    },
    login: function () {
        $(document).on('submit', 'form.login', function (e) {
            var form = $(this);
            e.preventDefault();
            var url = form.attr('action');
            form.spinner().start();
            $('form.login').trigger('login:submit', e);
            $.ajax({
                url: url,
                type: 'post',
                dataType: 'json',
                data: form.serialize(),
                success: function (data) {
                    form.spinner().stop();
                    if (!data.success) {
                        formValidation(form, data);
                        $('form.login').trigger('login:error', data);
                    } else {
                        $('form.login').trigger('login:success', data);
                        location.href = data.redirectUrl;
                    }
                },
                error: function (data) {
                    if (data.responseJSON.redirectUrl) {
                        window.location.href = data.responseJSON.redirectUrl;
                    } else {
                        $('form.login').trigger('login:error', data);
                        form.spinner().stop();
                    }
                }
            });
            return false;
        });
    },
    switchResetForm: function() {
        $(document).on('click', '#password-reset-header-link, .back-to-signin', function(e) {
            e.preventDefault();
            $('.modal-backdrop').hide();
            $('.account-header-menu').toggleClass('show');
            $('.password-reset-menu').toggleClass('show');
            $('.mobile-account-header-menu').toggleClass('show');
        })


    },
    //Show Pass Field Header
    showPassField: function() {
        var pass = $('.loginForm');
        var magiceye = $('.magiceye');
        var close = $('.closeeye');
    
        magiceye.on('click',function(){
            var magiceye = $('.magiceye');
            var close = $('.closeeye')
            if(close.hasClass('d-none')){
                close.removeClass('d-none')
                magiceye.addClass('d-none')
                pass.attr('type','text');
            }
        });
    
        close.on('click',function(){
            if(magiceye.hasClass('d-none')){
                magiceye.removeClass('d-none')
                close.addClass('d-none')
                pass.attr('type','password')
            }
        });
    }
}

