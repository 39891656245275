'use strict';

$(document).ready(function () {
    $(function () {
        if (!$('[data-toggle="tooltip"]').length) return;
        if (!$().tooltip) return; // checks for tooltip method

        $('[data-toggle="tooltip"]').tooltip();
    });
});
